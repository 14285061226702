import { getDisplayedPriceTierUsageValue } from '../../../../price-tier/utils/get-displayed-price-tier-usage-value'
import { Metric } from '../../../../../models'
import Typography from '../../../../../shared/components/general/typography'
import i18n from '../../../../../config/i18n'

interface Props {
  value: any;
}

// eslint-disable-next-line react/function-component-definition
const TableProratoionUsageCell = (metric: Metric) => (({ value }: Props) => {
  const { language } = i18n

  return (
    <Typography>
      {getDisplayedPriceTierUsageValue(value, metric.resourceType, language)}
    </Typography>
  )
})

export default TableProratoionUsageCell
