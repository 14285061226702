/* eslint-disable max-len */
import { TFunction } from 'i18next'
import Decimal from 'decimal.js'
import { PriceTier } from '../../../models'
import InvoicePriceTier from '../../../models/invoice-price-tier'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'
import { BillingType } from '../../price-tier/enum'
import { isInvoicePriceTier } from './type-checks'
import formatToPercentage from '../../../shared/utils/helpers/format-to-percentage'

const getInvoiceSummaryContent = (t: TFunction, language: string, priceTier: InvoicePriceTier | PriceTier) => {
  const usageString = isInvoicePriceTier(priceTier) ? `- ${t('invoice:price-tier-item:quantity')} ${priceTier.usage}` : ''
  const packageString = isInvoicePriceTier(priceTier) ? `- ${t('invoice:price-tier-item:package-quantity')} ${Math.ceil(priceTier.usage / priceTier.packageSize!)}` : ''

  const fixedPrice = priceTier.fixedPrice ?? '0'

  const displayedFixedPricing = new Decimal(fixedPrice).gt(0)
    ? `${t('invoice:price-tier-item:fixed-value')} ${formatToCurrency({ price: new Decimal(fixedPrice).times(100).toNumber(), language })} -`
    : ''

  const content = {
    [BillingType.Flat]: priceTier.price && `${t('invoice:price-tier-item:value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })}`,
    [BillingType.Unit]: priceTier.price && `${displayedFixedPricing} ${t('invoice:price-tier-item:unit-value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })} ${usageString}`,
    [BillingType.Package]: priceTier.price && `${displayedFixedPricing} ${t('invoice:price-tier-item:package-value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })} ${packageString}`,
    [BillingType.BasisPoints]: priceTier.basisPoints && `${displayedFixedPricing} ${t('invoice:price-tier-item:value')} ${formatToPercentage({ basisPoints: priceTier.basisPoints })}`,
  }
  return content[priceTier.billingType]
}

export { getInvoiceSummaryContent }
