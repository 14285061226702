import { useTranslation } from 'react-i18next'
import { Resource } from '../../../models'
import { ResourceType } from '../enum'

interface Props {
  rowData: Resource;
}

export function TableResourceTypeCell({ rowData }: Props) {
  const { t } = useTranslation(['resource', 'common'])

  return rowData.type === ResourceType.Unit ? t('resource:type-options:unit') : t('resource:type-options:currency')
}
