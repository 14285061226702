interface Props {
  basisPoints: number;
}

export default function formatToPercentage({ basisPoints }: Props) {
  return new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  ).format(basisPoints / 10000)
}
