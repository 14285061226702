import { useFormContext } from 'react-hook-form'
import {
  Text, Card,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiX } from 'react-icons/bi'
import PriceTierFromToInput from './price-tier-from-to-input'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import DecimalInput from '../../../shared/components/form/decimal-input'
import PriceTierBillingTypeBadge from './price-tier-billing-type-badge'
import { BillingType } from '../enum'
import { ResourceType } from '../../resource/enum'

interface Props {
  metricIndex: number,
  resourceType: ResourceType,
  index: number,
  remove: (index: number) => void,
}

export default function BillingFlatForm({
  metricIndex, index, remove, resourceType,
}: Props) {
  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  return (
    <Card withBorder padding="xs" radius="md">
      <div className="flex mb-4 justify-between">
        <div className="flex flex-row items-center gap-1">
          <Text className="text-gray-700" size="sm">
            {`${t('priceTier:billing-type')}:`}
          </Text>
          <PriceTierBillingTypeBadge type={BillingType.Flat} />
        </div>
        <BiX
          className="cursor-pointer text-gray-600"
          onClick={() => remove(index)}
          size={22}
        />
      </div>

      <div className="flex flex-wrap flex-row gap-4 items-center ml-2">
        <PriceTierFromToInput metricIndex={metricIndex} index={index} resourceType={resourceType} />
        <DecimalInput
          label={t('priceTier:fixed-amount')}
          className="w-50"
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.price && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          value={getValues(`metrics.${metricIndex}.priceTiers.${index}.price`) || '0'}
          onChange={(value: string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.price`, value)}
          required
        />
      </div>
    </Card>
  )
}
