import { Card, Text } from '@mantine/core'
import { AiFillInfoCircle } from 'react-icons/ai'

interface Props {
  title: string;
  description: string;
}

export default function InfoBox({
  title, description,
}: Props) {
  return (
    <Card padding="xs" radius="none" className="bg-blue-100">
      <div className="flex flex-row items-center gap-2">
        <AiFillInfoCircle className="w-20 text-blue-700" size={20} />
        <div>
          <Text size="sm" className="text-gray-900" fw={600}>
            {title}
          </Text>
          <Text size="sm" className="text-gray-700" fw={300}>
            {description}
          </Text>
        </div>
      </div>
    </Card>
  )
}
