import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react'
import { NumberInput, NumberInputProps } from '@mantine/core'
import { FiDollarSign } from 'react-icons/fi'
import { MdOutlinePercent } from 'react-icons/md'

type Props = Omit<NumberInputProps, 'onChange' | 'value'> & {
  centsInputType?: 'currency' | 'percentage',
  onChange: (value: number) => void,
  value: number | undefined,
  undefinedValueHandling?: 'zero' | 'empty'
}

const CentsInput = React.forwardRef(({
  onChange, value, centsInputType = 'currency', undefinedValueHandling = 'zero', ...props
}: Props, ref: any) => {
  const getFormattedValue = useCallback((rawValue: number | undefined) => {
    if (!rawValue) return undefinedValueHandling === 'zero' ? '0,00' : ''
    const centsValue = rawValue / 100
    const valueString = centsValue.toString()

    const parts = valueString.split('.')
    const integerPart = parts[0]

    if (parts[1]) {
      let decimalPart = parts[1].replace(/0+$/, '')
      if (decimalPart.length < 2) {
        decimalPart = decimalPart.padEnd(2, '0')
      }
      const formatted = `${integerPart},${decimalPart}`

      return formatted
    }

    return `${integerPart},00`
  }, [undefinedValueHandling])

  const [formattedValue, setFormattedValue] = useState<string>(getFormattedValue(value))

  const isFocusing = useRef<boolean>(false)

  const handleChange = (newValue: string | number) => {
    if (!isFocusing.current) return
    let updatedValue = '0'

    if (newValue !== '' && newValue !== '.') updatedValue = newValue.toString()
    if (newValue === '.') updatedValue = '0.00'

    const parts = updatedValue.split('.')
    if (parts.length === 2) {
      const integerPart = parts[0]
      const decimalPart = parts[1].replace(/0+$/, '')
      const valueFormatted = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      setFormattedValue(valueFormatted)
    }
    setFormattedValue(updatedValue)

    onChange(parseFloat(updatedValue.replace(',', '.')) * 100 || 0)
  }

  const handleBlur = () => {
    isFocusing.current = false
    setFormattedValue(getFormattedValue(value))
  }

  useEffect(() => {
    if (!isFocusing.current) {
      setFormattedValue(getFormattedValue(value))
    }
  }, [value, getFormattedValue])

  return (
    <NumberInput
      leftSection={centsInputType === 'currency' ? <FiDollarSign /> : <MdOutlinePercent />}
      value={formattedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={() => { isFocusing.current = true }}
      min={0}
      allowNegative={false}
      decimalSeparator=","
      hideControls
      decimalScale={2}
      ref={ref}
      {...props}
    />
  )
})

export default CentsInput
