import { useTranslation } from 'react-i18next'
import { ResourceType } from '../enum'

const useResourceTypeOptions = () => {
  const { t } = useTranslation(['common', 'resource'])

  return [
    { value: ResourceType.Unit, label: t('resource:type-options:unit') },
    { value: ResourceType.Currency, label: t('resource:type-options:currency') },
  ]
}

export default useResourceTypeOptions
