import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import MetricSummaryInfo from './metric-summary-info'
import PriceTierSummary from '../../price-tier/components/price-tier-summary'
import { useFilteredResourcesQuery } from '../../resource/queries'
// import MetricAmountLimitsSummary from './metric-amount-limits-summary'
import useAggregationLabel from '../utils/use-aggregation-label'
import { UpsertMetricAttributes } from '../../../interfaces'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'
import Typography from '../../../shared/components/general/typography'
import { BillingModel } from '../enum'
import priceTierDivisionLabel from '../utils/price-tier-division-label'
import { ResourceType } from '../../resource/enum'

interface Props {
  metrics: UpsertMetricAttributes[];
}

export default function MetricSummary({ metrics }: Props) {
  const {
    data: resources,
  } = useFilteredResourcesQuery()

  const { t, i18n } = useTranslation()
  const { language } = i18n

  const aggregationLabel = useAggregationLabel()

  if (!metrics || metrics.length === 0) {
    return (
      <div>
        <Text fz="sm" className="text-gray-600" fw={400}>{t('metric:empty-metrics')}</Text>
      </div>
    )
  }

  return (
    <div className="w-full">
      {metrics && metrics.map((metric) => {
        const metricResource = resources?.find((resource) => resource.id === metric.resourceId)
        const metricAggregation = aggregationLabel(metric.aggregation)
        const priceTierDivision = priceTierDivisionLabel(metric.priceTierDivision)
        return (
          <div key={metric.renderId || metric.id}>
            <div className="flex flex-col w-full mt-3 gap-1">

              <Typography size="sm" fw={500} className="text-purple-700">
                {metric.name}
              </Typography>

              {metricResource && (
                <div className="flex flex-col w-full mb-2">
                  <Typography size="md" fw={500}>
                    {metricResource?.name}
                  </Typography>
                  <Typography size="xs" className="text-gray-600" fw={400}>
                    {`ID: ${metricResource?.externalId}`}
                  </Typography>
                </div>
              )}

              {metric.billingModel === BillingModel.Proration ? (
                <MetricSummaryInfo label={t('metric:billing-model:label')} info={t('metric:billing-model:proration')} />
              ) : (
                <>
                  <MetricSummaryInfo label={t('metric:billing-model:label')} info={t('metric:billing-model:in-full')} />
                  <MetricSummaryInfo label={t('metric:aggregation')} info={metricAggregation} />
                </>
              )}
              <MetricSummaryInfo label={t('priceTier:division-method')} info={priceTierDivision} />

              <MetricSummaryInfo
                label={t('metric:fixed-amount')}
                info={formatToCurrency({ price: metric.fixedAmount, language })}
              />
              {!!metric.minimumAmount
                && (
                  <MetricSummaryInfo
                    label={t('metric:minimum-amount')}
                    info={formatToCurrency({ price: metric.minimumAmount, language })}
                  />
                )}

              <PriceTierSummary priceTiers={metric.priceTiers} resourceType={metricResource?.type || ResourceType.Unit} />
            </div>
          </div>
        )
      })}
    </div>
  )
}
