import { useTranslation } from 'react-i18next'
import { TableColumn } from '../../../interfaces'
import tableResourcesUtils from '../utils/table-resource-utils'
import { CoreTable } from '../../../shared/components/table'
import Resource from '../../../models/resource'
import { useFilteredResourcesQuery } from '../queries'
import { TableResourceMenuCell } from './table-resource-menu-cell'
import { TableResourceTypeCell } from './table-resource-type-cell'

export default function TableResource() {
  const { t } = useTranslation(['resource', 'common'])

  const {
    data: resources,
    isLoading,
    isFetching,
  } = useFilteredResourcesQuery()

  const tableResourceColumns: TableColumn<Resource>[] = [
    {
      label: t('resource:name'),
      key: 'name',
      columnKey: 'name',
      widthPercentage: 30,
    },
    {
      label: t('resource:type'),
      key: 'type',
      columnKey: 'type',
      Component: TableResourceTypeCell,
      widthPercentage: 30,
    },
    {
      label: t('resource:external-id'),
      key: 'externalId',
      columnKey: 'externalId',
      widthPercentage: 30,
    },
    {
      label: '',
      key: 'id',
      columnKey: 'resourceId',
      Component: TableResourceMenuCell,
      widthPercentage: 10,
    },
  ]

  return (
    <CoreTable<Resource>
      data={resources || []}
      indexKey={tableResourcesUtils.indexKey}
      columns={tableResourceColumns}
      isFetching={isFetching}
      isLoading={isLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
    />
  )
}
