import { Select, TextInput } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Resource } from '../../../models'
import { MAX_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import SafeTextInput from '../../../shared/components/form/safe-text-input'
import Tooltip from '../../../shared/components/form/tooltip-input'
import useResourceTypeOptions from '../hooks/use-resource-type-options'
import InfoBox from '../../../shared/components/alert/info-box'

interface Props {
  isUpdate?: boolean;
}

export default function ResourceForm({ isUpdate = false }: Props = {}) {
  const { t } = useTranslation(['common', 'resource'])

  const resourceTypeOptions = useResourceTypeOptions()

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<Resource>()

  return (
    <div>
      <TextInput
        className="mb-4"
        label={t('resource:name') as string}
        placeholder={t('resource:name') as string}
        error={errors.name && errors.name.message}
        required
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <Controller
        name="type"
        control={control}
        rules={{ required: !isUpdate }}
        render={({ field }) => (
          <Select
            {...field}
            className="w-full mb-4"
            allowDeselect={false}
            label={`${t('resource:type') as string}`}
            placeholder={t('resource:type') as string}
            data={resourceTypeOptions}
            error={errors.type && errors.type.message}
            required={!isUpdate}
            searchable
            disabled={isUpdate}
          />
        )}
      />

      {isUpdate ? (
        <SafeTextInput
          label={t('resource:external-id') as string}
          placeholder={t('resource:external-id') as string}
          error={errors.externalId && errors.externalId.message}
          descriptionText={t('resource:form:external-id-alert') as string}
          rightSection={<Tooltip text={t('resource:form:external-id-description') as string} />}
          required
          {...register('externalId', {
            required: { value: true, message: t('common:form-error:required-field') },
            maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          })}
        />
      ) : (
        <TextInput
          label={t('resource:external-id') as string}
          placeholder={t('resource:external-id') as string}
          error={errors.externalId && errors.externalId.message}
          rightSection={<Tooltip text={t('resource:form:external-id-description') as string} />}
          required
          {...register('externalId', {
            required: { value: true, message: t('common:form-error:required-field') },
            maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
          })}
        />
      )}

      <div className="mt-5">
        <InfoBox
          title={t('resource:form:type-info-box:title')}
          description={t('resource:form:type-info-box:description')}
        />
      </div>
    </div>
  )
}
