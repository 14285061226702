import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { NumberInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { PriceTier } from '../../../models'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import PRICE_TIER_STEP from '../constants/price-tier-step'
import { ResourceType } from '../../resource/enum'
import CentsInput from '../../../shared/components/form/cents-input'

interface Props {
  metricIndex: number,
  index: number,
  resourceType: ResourceType
}

export default function PriceTierFromToInput({ metricIndex, index, resourceType }: Props) {
  const [fromValue, setFromValue] = useState<number>(1)
  const [toValue, setToValue] = useState<number>()

  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const currentPriceTiers = getValues(`metrics.${metricIndex}.priceTiers`) || []

  const isFirstPriceTier = index === 0
  const isLastPriceTier = index === currentPriceTiers.length - 1

  const previousToValue = currentPriceTiers[index - 1]?.to
  const currentToValue = currentPriceTiers[index]?.to

  const fixNextToValues = (initialIndex: number, value: number) => {
    const nextToValue = getValues(`metrics.${metricIndex}.priceTiers.${index + 1}.to`)

    if (!nextToValue) return
    if ((nextToValue) > value + 1) return

    let currentIndex = initialIndex
    let fixStepValue = PRICE_TIER_STEP

    while (getValues(`metrics.${metricIndex}.priceTiers.${currentIndex + 1}.to`)) {
      setValue(`metrics.${metricIndex}.priceTiers.${currentIndex + 1}.to`, value + fixStepValue as PriceTier['to'])
      currentIndex += 1
      fixStepValue += fixStepValue
    }
  }

  useEffect(
    () => {
      if (currentToValue) {
        setToValue(currentToValue)
      }
    },
    [currentToValue, index, metricIndex, setValue],
  )

  useEffect(() => {
    if (isFirstPriceTier) {
      setFromValue(1)
      setValue(`metrics.${metricIndex}.priceTiers.${index}.from`, 1)
    }

    if (isLastPriceTier) {
      setToValue(undefined)
      setValue(`metrics.${metricIndex}.priceTiers.${index}.to`, null)
    }

    // If there is an valid previous price tier TO value, the current
    // price tier FROM value must be the previous TO value + 1
    if (previousToValue) {
      const newInputFromValue = previousToValue + 1
      setFromValue(newInputFromValue)
      setValue(`metrics.${metricIndex}.priceTiers.${index}.from`, newInputFromValue)
    }
  }, [getValues, index, isFirstPriceTier, isLastPriceTier, metricIndex, previousToValue, setValue])

  return (
    <div className="flex w-content flex-row gap-4">
      { resourceType === ResourceType.Unit ? (
        <NumberInput
          label={t('priceTier:from')}
          className="w-50"
          min={1}
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.from && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          onChange={(value: number | string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.from`, Number(value) as PriceTier['from'])}
          value={fromValue}
          disabled
          required
        />
      ) : (
        <CentsInput
          className="w-50"
          label={t('priceTier:from')}
          min={1}
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.from && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          onChange={(value: number | string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.from`, Number(value) as PriceTier['from'])}
          value={fromValue}
          undefinedValueHandling="empty"
          disabled
          required
        />
      )}

      { resourceType === ResourceType.Unit ? (
        <NumberInput
          label={t('priceTier:summary:until')}
          className="w-50"
          min={fromValue}
          placeholder={isLastPriceTier ? t('common:form-placeholder:infinity') as string : t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.to && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.to?.message}
          onChange={(value: number | string) => {
            fixNextToValues(index, Number(value))
            setToValue(Number(value))
            setValue(`metrics.${metricIndex}.priceTiers.${index}.to`, Number(value) as PriceTier['to'])
          }}
          disabled={isLastPriceTier}
          value={toValue}
          required
        />
      ) : (
        <CentsInput
          className="w-50"
          label={t('priceTier:summary:until')}
          min={fromValue}
          placeholder={isLastPriceTier ? t('common:form-placeholder:infinity') as string : t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.to && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.to?.message}
          onChange={(value: number | string) => {
            fixNextToValues(index, Number(value))
            setToValue(Number(value))
            setValue(`metrics.${metricIndex}.priceTiers.${index}.to`, Number(value) as PriceTier['to'])
          }}
          disabled={isLastPriceTier}
          undefinedValueHandling="empty"
          value={toValue}
          required
        />
      )}
    </div>
  )
}
