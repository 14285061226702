import {
  Menu, Button, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { AiOutlinePlus } from 'react-icons/ai'
import { useFormContext } from 'react-hook-form'
import { BillingType } from '../enum'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import { SelectWithDescription } from '../../../shared/components/combo-box/select-with-description'
import { PriceTierDivision } from '../../metric/enum'
import priceTierDivisionOptions from '../../metric/utils/price-tier-division-options'
import { billingTypesByResourceType } from '../utils/billing-types-by-resource-type'
import { Resource } from '../../../models'

interface Props {
  handleAppendNewPriceTier: (billingType: BillingType) => void;
  metricIndex: number
  metricResource?: Resource
}

export default function PriceTierFormHeader({ handleAppendNewPriceTier, metricIndex, metricResource }: Props) {
  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, watch,
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const priceTierDivision = watch(`metrics.${metricIndex}.priceTierDivision`)
  const priceTierDivisionSelectOptions = priceTierDivisionOptions()

  const getPriceTierMenuItem = (billingType: BillingType) => {
    const billingTypeLabels = {
      [BillingType.Flat]: t('priceTier:billing-type-flat'),
      [BillingType.Package]: t('priceTier:billing-type-package'),
      [BillingType.Unit]: t('priceTier:billing-type-unit'),
      [BillingType.BasisPoints]: t('priceTier:billing-type-basis-points'),
    }

    return (
      <Menu.Item
        key={billingType}
        onClick={() => handleAppendNewPriceTier(billingType)}
      >
        {billingTypeLabels[billingType]}
      </Menu.Item>
    )
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-1">
        <Title order={6}>
          {t('priceTier:price-tiers')}
        </Title>
      </div>

      <SelectWithDescription<PriceTierDivision>
        value={priceTierDivision || PriceTierDivision.Progressive}
        setValue={(priceTierDivisionSelected) => setValue(`metrics.${metricIndex}.priceTierDivision`, priceTierDivisionSelected)}
        data={priceTierDivisionSelectOptions}
        placeholder={t('priceTier:division-method')}
        label={t('priceTier:division-method')}
      />

      <div className="flex flex-row justify-between items-center mt-4">
        <Menu
          shadow="md"
          width={200}
        >
          <Menu.Target>
            <Button
              className="ml-auto"
              leftSection={<AiOutlinePlus size={22} />}
              variant="subtle"
              size="xs"
              disabled={!metricResource}
            >
              {t('priceTier:add-price-tier')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {metricResource && billingTypesByResourceType[metricResource.type].map((billingType) => getPriceTierMenuItem(billingType))}
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  )
}
