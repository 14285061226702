import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import InvoicePriceTierItemContent from './invoice-price-tier-item-content'
import InvoicePriceTierItemHeader from './invoice-price-tier-item-header'
import { getInvoiceSummaryContent } from '../../../utils/get-invoice-summary-content'
import InvoicePriceTier from '../../../../../models/invoice-price-tier'
import { isInvoicePriceTier } from '../../../utils/type-checks'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import { PriceTier } from '../../../../../models'
import { ResourceType } from '../../../../resource/enum'
import { getDisplayedPriceTierUsageValue } from '../../../../price-tier/utils/get-displayed-price-tier-usage-value'

interface Props {
  priceTier: InvoicePriceTier | PriceTier
  resourceType: ResourceType
}

export default function InvoicePriceTierItem({ priceTier, resourceType }: Props) {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <div key={priceTier.id} className="flex flex-col justify-start w-full items-start mb-5">
      <div className="flex flex-col justify-start w-full gap-1">
        <InvoicePriceTierItemHeader
          billingType={priceTier.billingType}
          from={priceTier.from}
          to={priceTier.to}
          packageSize={priceTier.packageSize}
          resourceType={resourceType}
        />
        <InvoicePriceTierItemContent
          left={<Text size="sm">{getInvoiceSummaryContent(t, language, priceTier)}</Text>}
          right={isInvoicePriceTier(priceTier) && formatToCurrency({ price: priceTier.totalAmount, language })}
        />
        {isInvoicePriceTier(priceTier) && (
          <Text className="-mt-2" c="gray.6" size="xs">
            {t('invoice:usage-quantity', {
              usageAmount: getDisplayedPriceTierUsageValue(priceTier.usage, resourceType, language),
            })}
          </Text>
        )}
      </div>
    </div>
  )
}
