import { useFormContext } from 'react-hook-form'
import {
  Text, Card,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiX } from 'react-icons/bi'
import PriceTierFromToInput from './price-tier-from-to-input'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import DecimalInput from '../../../shared/components/form/decimal-input'
import { BillingType } from '../enum'
import PriceTierBillingTypeBadge from './price-tier-billing-type-badge'
import CentsInput from '../../../shared/components/form/cents-input'
import { ResourceType } from '../../resource/enum'

interface Props {
  metricIndex: number,
  index: number,
  remove: (index: number) => void,
}

export default function BillingBasisPointsForm({ metricIndex, index, remove }: Props) {
  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  return (
    <Card withBorder padding="xs" radius="md">
      <div className="flex mb-4 justify-between">
        <div className="flex flex-row items-center gap-1">
          <Text className="text-gray-700" size="sm">
            {`${t('priceTier:billing-type')}:`}
          </Text>
          <PriceTierBillingTypeBadge type={BillingType.BasisPoints} />
        </div>
        <BiX
          className="cursor-pointer text-gray-600"
          onClick={() => remove(index)}
          size={22}
        />
      </div>
      <div className="flex flex-col gap-2 items-start ml-2">
        <div className="flex flex-wrap flex-row gap-4 items-center">
          <PriceTierFromToInput metricIndex={metricIndex} index={index} resourceType={ResourceType.Currency} />
          <DecimalInput
            label={t('priceTier:fixed-amount')}
            className="w-50"
            placeholder={t('common:form-placeholder:zero') as string}
            error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.fixedPrice && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
            value={getValues(`metrics.${metricIndex}.priceTiers.${index}.fixedPrice`) ?? '0'}
            onChange={(value: string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.fixedPrice`, value)}
            required
          />
        </div>
        <CentsInput
          label={t('priceTier:billing-type-basis-point-title')}
          className="w-50 mt-2"
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.basisPoints && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          value={getValues(`metrics.${metricIndex}.priceTiers.${index}.basisPoints`) || 0}
          onChange={(value: number) => setValue(`metrics.${metricIndex}.priceTiers.${index}.basisPoints`, value)}
          centsInputType="percentage"
          required
        />
      </div>
    </Card>
  )
}
