import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface Props {
  fixedAmount: string
  minimumAmount: string

}

export default function PlanSummaryInfo({ fixedAmount, minimumAmount }: Props) {
  const { t } = useTranslation(['plan', 'common'])

  return (
    <div className="mt-4 flex flex-col gap-2">
      <div className="flex flex-row w-full justify-between">
        <Text fz="md">
          {t('common:fixed-amount')}
        </Text>
        <Text fz="md" className="text-gray-600">
          {fixedAmount}
        </Text>
      </div>
      {!!minimumAmount && (
        <div className="flex flex-row w-full justify-between">
          <Text fz="md">
            {t('common:minimum-amount')}
          </Text>
          <Text fz="md" className="text-gray-600">
            {minimumAmount}
          </Text>
        </div>
      )}
    </div>
  )
}
