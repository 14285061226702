import { Badge, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { BillingType } from '../../../../price-tier/enum'
import { ResourceType } from '../../../../resource/enum'
import i18n from '../../../../../config/i18n'
import { getDisplayedPriceTierUsageValue } from '../../../../price-tier/utils/get-displayed-price-tier-usage-value'

interface Props {
  billingType: BillingType;
  from: number;
  to: number | null;
  packageSize: number | null;
  resourceType: ResourceType;
}

export default function InvoicePriceTierItemHeader({
  from, to, billingType, packageSize = null, resourceType,
}: Props) {
  const { t } = useTranslation(['invoice', 'metric'])
  const { language } = i18n

  const isLastTier = to === null

  const priceTierFrom = useMemo(() => getDisplayedPriceTierUsageValue(from, resourceType, language), [from, resourceType, language])
  const priceTierTo = useMemo(() => to != null && getDisplayedPriceTierUsageValue(to, resourceType, language), [to, resourceType, language])

  return (
    <div className="flex gap-2 text-gray-700">
      <Badge className="normal-case" color="violet" variant="filled">
        {isLastTier ? (
          <Text>{`${t('metric:summary:from')} ${priceTierFrom}`}</Text>
        ) : (
          <Text>{`${priceTierFrom} ${t('metric:summary:until')} ${priceTierTo}`}</Text>
        )}
      </Badge>
      <Text size="sm" fw={600}>
        {packageSize ? (`${t(`invoice:billing-type-${billingType}`)} ${packageSize} ${t('invoice:units')}`)
          : t(`invoice:billing-type-${billingType}`)}
      </Text>
    </div>
  )
}
