import { useTranslation } from 'react-i18next'
import TableProrationSumaryRow from './table-proration-sumary-row'
import { InvoiceMetricProration } from '../../../../../models/invoice-metric'
import ProrationUsage from '../../../../../models/proration-usage'
import { TableColumn } from '../../../../../interfaces'
import tableProrationUsageUtils from '../../../utils/table-proration-usage-utils'
import { DetailTable } from '../../../../../shared/components/table'
import { TableCurrencyCell } from '../../../../../shared/components/table/table-currency-cell'
import { TableProrationIntervalCell } from './table-proration-interval-cell'
import TableProratoionUsageCell from './table-proration-usage-cell'

interface Props {
  metric: InvoiceMetricProration;
}

export default function TableProrationView({ metric }: Props) {
  const { t } = useTranslation(['invoice', 'common'])

  const tableUserColumns: TableColumn<ProrationUsage>[] = [
    {
      label: t('common:date'),
      key: 'id',
      columnKey: 'id',
      widthPercentage: 40,
      Component: TableProrationIntervalCell,
    },
    {
      label: t('invoice:quantity'),
      key: 'usage',
      columnKey: 'usage',
      Component: TableProratoionUsageCell(metric),
      widthPercentage: 27.5,
    },
    {
      label: t('invoice:value'),
      key: 'amount',
      columnKey: 'amount',
      widthPercentage: 32.5,
      Component: TableCurrencyCell,
    },
  ]

  return (
    <div className="h-fit">
      <DetailTable<ProrationUsage>
        data={metric.prorationMetricBillings}
        indexKey={tableProrationUsageUtils.indexKey}
        columns={tableUserColumns}
        SummaryRow={TableProrationSumaryRow}
        summaryRowProps={metric}
      />
    </div>
  )
}
