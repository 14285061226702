import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import { useMemo } from 'react'
import { BillingType } from '../enum'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'
import { UpsertPriceTierAttributes } from '../../../interfaces'
import formatToPercentage from '../../../shared/utils/helpers/format-to-percentage'
import { ResourceType } from '../../resource/enum'
import { getDisplayedPriceTierUsageValue } from '../utils/get-displayed-price-tier-usage-value'

interface Props {
  priceTier: UpsertPriceTierAttributes
  resourceType: ResourceType
}

export default function PriceTierSummaryInfo({
  priceTier, resourceType,
}: Props) {
  const { t, i18n } = useTranslation(['common', 'priceTier'])
  const { language } = i18n

  const commonTextProps = { fz: 'sm', fw: 400, className: 'text-gray-600' }

  const priceTierFrom = useMemo(() => getDisplayedPriceTierUsageValue(priceTier.from, resourceType, language), [priceTier, resourceType, language])
  const priceTierTo = useMemo(() => priceTier.to != null && getDisplayedPriceTierUsageValue(priceTier.to, resourceType, language), [priceTier, resourceType, language])

  const fromToText = useMemo(() => {
    if ((!priceTier.from || priceTier.from === 0) && priceTier.to) {
      return `${t('priceTier:summary:until')} ${priceTierTo}`
    } if (priceTier.from > 0 && priceTier.to) {
      return `${t('priceTier:from')} ${priceTierFrom} ${t('priceTier:to')} ${priceTierTo}`
    } if (priceTier.from > 0 && !priceTier.to) {
      return `${t('priceTier:summary:from')} ${priceTierFrom}`
    }
    return `${t('priceTier:summary:complete')}`
  }, [priceTierFrom, priceTierTo, priceTier, t])

  const billingTexts = {
    [BillingType.Flat]: t('priceTier:summary:flat'),
    [BillingType.Unit]: t('priceTier:summary:unit'),
    [BillingType.Package]: priceTier.packageSize ? `${t('priceTier:summary:package')} ${priceTier.packageSize} ${t('priceTier:summary:unit')}` : null,
    [BillingType.BasisPoints]: t('priceTier:summary:basis-points'),
  }

  const displayedFixedPricing = priceTier.fixedPrice ?? '0'

  return (
    <div>
      <div className="grid grid-cols-3 text-left">
        <div>
          {fromToText && <Text {...commonTextProps}>{fromToText}</Text>}
        </div>
        <div className="flex flex-col items-end text-right">
          {new Decimal(displayedFixedPricing).gt(0) && <Text fz="sm" fw={500}>{formatToCurrency({ price: new Decimal(displayedFixedPricing).times(100).toNumber(), language })}</Text>}
          {new Decimal(displayedFixedPricing).gt(0) && billingTexts[priceTier.billingType] && <Text {...commonTextProps}>{billingTexts[BillingType.Flat]}</Text>}
        </div>
        <div className="flex flex-col items-end text-right">
          <Text fz="sm" fw={500}>
            {priceTier.billingType !== BillingType.BasisPoints && priceTier.price && formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })}
            {priceTier.billingType === BillingType.BasisPoints && priceTier.basisPoints && formatToPercentage({ basisPoints: priceTier.basisPoints })}
          </Text>
          {billingTexts[priceTier.billingType] && <Text {...commonTextProps}>{billingTexts[priceTier.billingType]}</Text>}
        </div>
      </div>
    </div>
  )
}
